<template>
  <b-col md="12">
    <section class="policies" v-if="hotel.hotel_policies.length">
      <p class="policies__title" v-html="'Informazioni importanti'" />
      <div class="policies__wrap">
        <div class="policies__item" v-for="(policy, policy_index) in hotel.hotel_policies" v-bind:key="_uid + policy_index">
          <p class="policies__item-title" v-html="policy.name" />
          <span class="policies__item-desc" v-html="policy.content" />
        </div>
      </div>
    </section>
  </b-col>
</template>

<script>

export default {
  name: 'policies',

  components: {
  },

  props: {
    hotel: Object
  },

  data() {
    return {
    }
  },

  computed: {
  },

  created() {
  },

  mounted() {
  },

  methods: {
  },
}
</script>

<style lang="scss" scoped>

  .policies {
    margin-top: $grid-gutter-width*2;

    &__title {
      font-size: $font-size-lg;
      font-weight: $vb-font-weight-semibold;
      margin-bottom: 20px;
    }

    &__wrap {
      background-color: $white;
      padding: 20px;
      border-radius: 10px;
    }

    &__item {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }

      &-title {
        font-weight: $vb-font-weight-semibold;
        margin-bottom: 10px;
      }
    }
  }

</style>