<template>
  <b-col md="12">
    <section class="accommodations">
      <card-recap :show_header="false" :show_footer="false" :show_matching="true" :solution="solution" :accommodations="accommodations" />
    </section>
  </b-col>
</template>

<script>
import CardRecap from "@booking/components/blocks/card-recap.vue"

export default {
  name: 'accommodations',

  components: {
    'card-recap': CardRecap,
  },

  props: {
    solution: Object,
    accommodations: Object,
  },

  data() {
    return {
    }
  },

  computed: {
  },

  created() {
  },

  mounted() {
  },

  methods: {
  },
}
</script>

<style lang="scss" scoped>

  .accommodations {
    margin-top: $grid-gutter-width*2;
  }

</style>