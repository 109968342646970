<template>
  <b-col md="12" xl="6">
    <section class="reservation">
      <p class="reservation__title" v-html="'I dati della tua prenotazione'" />
      <div class="reservation__wrap">
        <div class="reservation__check">
          <span class="reservation__date" v-html="'Arrivo ' + '<strong class=\'semibold\'>' + reservation.arrival + '</strong>'" />
          <span class="reservation__time" v-html="reservation.checkin" />
        </div>
        <div class="reservation__check">
          <span class="reservation__date" v-html="'Partenza ' + '<strong class=\'semibold\'>' + reservation.departure + '</strong>'" />
          <span class="reservation__time" v-html="reservation.checkout" />
        </div>
      </div>
      <p class="reservation__duration" v-html="'Durata del soggiorno: <strong class=\'semibold\'>' + reservation.nights + '</strong>'" />
      <div class="reservation__info">
        <span class="reservation__info-text semibold" v-html="reservation.rooms" />
        <span class="reservation__info-text semibold ml-1" v-html="'per ' + reservation.adults" />
        <span class="reservation__info-text semibold ml-1" v-if="reservation.children" v-html="'e ' + reservation.children"
        />
      </div>
    </section>
  </b-col>
</template>

<script>
import {format_date_with_month} from '@booking/utilities.js'
import moment from 'moment';

export default {
  name: 'reservation',

  components: {
  },

  props: {
    solution: Object,
    hotel: Object,
  },

  data() {
    return {
      reservation: this.get_reservation_info()
    }
  },

  computed: {
  },

  created() {
  },

  mounted() {
  },

  methods: {

    get_reservation_info() {
      const {checkin, checkout, blocks, occupancy} = this.solution.checkout_payload,
            {checkin_from, checkin_to, checkout_from, checkout_to} = this.hotel.checkin_checkout_times


      const nights =  this.total_nights(checkin, checkout),
            nights_label = nights > 1 ? 'notti' : 'notte',
            rooms = Object.values(blocks).reduce((total, value) => total + value, 0),
            rooms_label = rooms > 1 ? 'camere' : 'camera',
            adults = occupancy.reduce((total, item) => total + item.adults, 0),
            adults_label = adults > 1 ? 'adulti' : 'adulto',
            children = occupancy.reduce((total, item) => total + item.children.length, 0),
            children_label = children > 1 ? 'bambini' : 'bambino'

      return {
        arrival: format_date_with_month(checkin),
        departure: format_date_with_month(checkout),
        checkin: this.formatted_ckin_ckout(checkin_from, checkin_to),
        checkout: this.formatted_ckin_ckout(checkout_from, checkout_to),
        nights: `${nights} ${nights_label}`,
        rooms: `${rooms} ${rooms_label}`,
        adults: `${adults} ${adults_label}`,
        children: children > 0 && `${children} ${children_label}`,
      }
    },

    total_nights(from_time, to_time) {
      const formatted_from = moment(from_time, 'YYYY-MM-DD')
      const formatted_to = moment(to_time, 'YYYY-MM-DD')
      return formatted_to.diff(formatted_from, 'days')
    },

    formatted_ckin_ckout(...val){
      return val.filter(str => str !== "").join(' - ')
    }

  },
}
</script>

<style lang="scss" scoped>

  .reservation {
    background-color: #fafcff;
    border-radius: 10px;
    padding: 15px 20px;
    border: 1px solid $gray-300;
    height: 100%;
    margin-top: $grid-gutter-width;

    @include media-breakpoint-up(xl){
      margin-top: 0;
    }

    &__title {
      font-size: $font-size-lg;
      font-weight: $vb-font-weight-semibold;
      margin-bottom: 10px;
    }

    &__wrap {
      display: flex;
      align-items: center;
    }

    &__check {
      span {
        display: block;
      }

      &:first-child {
        border-right: 1px solid $gray-300;
        margin-right: 20px;
        padding-right: 20px;
      }
    }

    &__duration {
      margin: 20px 0 5px;
    }

    &__info {
      display: flex;
      align-items: center;
    }
  }

</style>