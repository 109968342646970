<template>
  <b-col md="12">
    <section class="comments">
      <p class="comments__title" v-html="'Richieste speciali'" />

      <div class="comments__wrap">
        <p v-html="'La struttura potrebbe non soddisfare le richieste speciali, ma farà comunque il possibile per venire incontro alle tue esigenze.<br>\
         Potrai sempre contattare la struttura per richieste speciali anche dopo la prenotazione.'" />

        <div class="comments_form">
          <p v-html="'<strong>Scrivi le tue richieste in inglese o in italiano.</strong> <span class=\'text--sm\'>(facoltativo)</span>'" />
          <b-form-textarea v-model="message" @input="handler_comments" no-resize rows="3" />
        </div>
      </div>

    </section>
  </b-col>
</template>

<script>

export default {
  name: 'comments',

  components: {
  },

  props: {
  },

  data() {
    return {
      message: '',
    }
  },

  computed: {
  },

  created() {
  },

  mounted() {
  },

  methods: {
    handler_comments() {
      this.$emit('get_comments', this.message);
    }
  },
}
</script>

<style lang="scss" scoped>

  .comments {
    margin-top: $grid-gutter-width*2;

    &__title {
      font-size: $font-size-lg;
      font-weight: $vb-font-weight-semibold;
      margin-bottom: 20px;
    }

    &__wrap {
      background-color: $white;
      padding: 20px;
      border-radius: 10px;
    }
  }

</style>