<template>
  <div class="checkout-content-block">

    <div class="checkout">
      <b-row>
        <b-col md="12">
          <div class="checkout__step">
            <div class="checkout__step-item">
              <vb-icon class="checkout__step-symbol" :name="'check'" :color="'ffffff'" :size="16" />
              <router-link class="checkout__step-label" :to="{name: 'detail'}" v-html="'Scelta camere'" />
            </div>
            <div class="checkout__step-item">
              <span class="checkout__step-symbol" v-html="'2'" />
              <span class="checkout__step-label" v-html="'Ultimo step'" />
            </div>
          </div>
        </b-col>

        <template v-for="(block,key) in checkout_blocks">
          <component
            v-if="block.show"
            v-bind:key="_uid+block+key"
            :is="block.key"
            :hotel="hotel"
            :solution="solution"
            :accommodations="accommodations"
            @get_comments="emit_comments"
          />
        </template>
      </b-row>
    </div>
  </div>

</template>

<script>
import HotelInfo from '@booking/components/blocks/checkout/hotel-info.vue'
import Reservation from '@booking/components/blocks/checkout/reservation.vue'
import Accommodations from '@booking/components/blocks/checkout/accommodations.vue'
import Conditions from '@booking/components/blocks/checkout/conditions.vue'
import Policies from '@booking/components/blocks/checkout/policies.vue'
import Info from '@booking/components/blocks/checkout/info.vue'
import Comments from '@booking/components/blocks/checkout/comments.vue'

export default {
  name: 'checkout-content-block',

  components: {
    'hotel-info': HotelInfo,
    'reservation': Reservation,
    'accommodations': Accommodations,
    'conditions': Conditions,
    'policies': Policies,
    'info': Info,
    'comments': Comments,
  },

  props: {
    solution: Object,
    hotel: Object,
  },

  data() {
    return {
      checkout_blocks: [],
      accommodations: this.get_accommodations(),
    }
  },

  computed: {
  },

  created() {
  },

  mounted() {
    this.checkout_blocks = [
      {
        key: "hotel-info",
        show: true,
      },
      {
        key: "reservation",
        show: true,
      },
      {
        key: "accommodations",
        show: true,
      },
      {
        key: "conditions",
        show: true,
      },
      {
        key: "policies",
        show: true,
      },
      {
        key: "info",
        show: true,
      },
      {
        key: "comments",
        show: true,
      },
    ]
  },

  methods: {
    emit_comments(message) {
      this.$emit('comments', message);
    },

    get_accommodations(){
      const {rooms} = this.solution
      let block_ids = [], block_quantities = [], incremental_prices = []

      rooms.forEach(room => {
        for(const block in room.blocks) {
          block_ids.push(block)
          block_quantities.push(room.blocks[block].incremental_price_selected.quantity)
          incremental_prices.push(room.blocks[block].incremental_price_selected.price)
        }
      })

      block_ids = block_ids.join(',')
      block_quantities = block_quantities.join(',')
      incremental_prices = incremental_prices.join(',')

      return {
        multiroom_assignment: false,
        block_ids: block_ids,
        block_quantities: block_quantities,
        incremental_prices: incremental_prices
      }
    },
  },
}
</script>

<style lang="scss" scoped>

  .checkout {

    &__step {
      $this_step : &;
      background-color: $white;
      border-radius: 10px;
      padding: 15px 20px;
      display: flex;
      align-items: center;
      position: relative;
      margin-bottom: $grid-gutter-width*2;

      &:before {
        content: '';
        position: absolute;
        top: 3px;
        left: 175px;
        right: 57.5%;
        bottom: 0;
        margin: auto;
        height: 1px;
        background-color: $gray-200;
        display: none;

        @include media-breakpoint-up(lg) {
          display: block;
        }
      }

      &-item {
        display: flex;
        align-items: center;
        flex-basis: 50%;

        @include media-breakpoint-up(sm) {
          flex-basis: 33%;
        }

        &:last-child {
          justify-content: center;
        }
      }

      &-symbol {
        background-color: $primary;
        border-radius: 50px;
        width: 25px;
        height: 25px;
        color: $white;
        font-size: $font-size-md;
        font-weight: $vb-font-weight-semibold;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &-label {
        font-weight: $vb-font-weight-semibold;
        margin-left: 10px;

        @at-root #{$this_step}-item:first-child & {
          text-decoration: underline;

        }
      }
    }
  }

</style>