<template>
  <b-col md="12">
    <section class="conditions">
    <p class="conditions__title" v-html="'Condizioni per i bambini e letti supplementari'" />
    <div class="conditions__wrap">
      <div class="conditions__item">
        <p class="conditions__item-title" v-html="'<span class=\'semibold\'>Condizioni per i bambini</span>'" />
        <p class="conditions__item-desc" v-html="
            'Il prezzo indicato si riferisce al numero di adulti e bambini.<br/> ' +
            'Assicurati di aver aggiunto alla ricerca il numero esatto di bambini che soggiorneranno e la loro età, perché questi dettagli incidono sul prezzo.'"
        />
      </div>
      <div class="conditions__item">
        <p class="conditions__item-title" v-html="'<span class=\'semibold\'>Condizioni per culle e letti aggiuntivi</span>'" />
        <ul class="conditions__item-list">
          <li class="conditions__item-li" v-html="'Le richieste sono soggette a disponibilità'" />
          <li class="conditions__item-li" v-html="'Le richieste devono essere confermate, <span class=\'semibold\'>contatta la struttura</span>'" />
          <li class="conditions__item-li" v-html="'Le richieste non contrassegnate con \'Gratis\' potrebbero comportare costi extra'" />
        </ul>
      </div>

      <span class="conditions__read" v-b-modal="'modal_conditions'" v-html="'Leggi tutte le condizioni per culle e letti supplementari'" />

      <vb-modal
          :vb_options="{
            id: 'modal_conditions',
            body_class: 'conditions__modal',
            title: 'Condizioni per culle e letti aggiuntivi',
            size: 'lg',
            centered: true,
            hide_footer: true
          }"
      >
        <cots-extrabeds class="conditions__modal-cots-extrabeds" :hotel="hotel" />

        <div class="conditions__modal-text">
          <p v-html="'I supplementi non sono inclusi nell’importo totale e devono essere pagati separatamente durante il soggiorno.'" />
          <p v-html="'Il numero massimo di letti e culle extra dipende dalla sistemazione che hai scelto. Verifica la tua sistemazione per sapere qual è l\'occupazione massima.'" />
          <p v-html="'Le culle e i letti supplementari sono soggetti a disponibilità. <strong class=\'semibold\'>Contatta la struttura</strong>.'" />
        </div>

      </vb-modal>

      <div class="conditions__extrabeds">
        <p class="conditions__extrabeds-title" v-if="hotel.additional_policies.cots_and_extra_beds.length" v-html="'In questa struttura sono soggetti a disponibilità: '" />
        <ul class="conditions__extrabeds-list">
          <template v-for="(extra_bed, extra_bed_index) in hotel.additional_policies.cots_and_extra_beds">
            <li class="conditions__extrabeds-li" v-bind:key="_uid + extra_bed_index">
              <span v-html="get_cots_extrabeds(extra_bed.rate_type).name" />
              <span>
                <strong>da {{extra_bed.from_age}} <span v-if="!extra_bed.to_age">anni e oltre</span></strong>
                <strong v-if="extra_bed.to_age" v-html="'a ' + extra_bed.to_age + ' anni'"></strong>
              </span>
            </li>
          </template>
        </ul>
      </div>
    </div>
  </section>
  </b-col>
</template>

<script>
import { get_cots_extrabeds } from '@booking/resources'
import CotsExtraBeds from "@booking/components/blocks/cots-extrabeds.vue";

export default {
  name: 'conditions',

  components: {
    'cots-extrabeds': CotsExtraBeds,
  },

  props: {
    hotel: Object
  },

  data() {
    return {
    }
  },

  computed: {
  },

  created() {
    this.get_cots_extrabeds = get_cots_extrabeds
  },

  mounted() {
  },

  methods: {
  },
}
</script>

<style lang="scss" scoped>

  .conditions {
    margin-top: $grid-gutter-width*2;

    &__title {
      font-size: $font-size-lg;
      font-weight: $vb-font-weight-semibold;
      margin-bottom: 20px;
    }

    &__wrap {
      padding: 15px 20px;
      border: 1px solid $gray-300;
      background-color: #fafcff;
      border-radius: 10px;
    }

    &__item {
      &_title {
        margin-bottom: 10px;
      }
    }

    &__read {
      color: $info;
      font-weight: $vb-font-weight-semibold;
      text-decoration: underline;
    }

    &__extrabeds {

      &-title {
        border-top: 1px solid $gray-200;
        padding-top: 15px;
        margin-top: 20px;
        margin-bottom: 10px;
      }

      &-list {
        margin-bottom: 0;
      }

      &-li {


        & > span {
          &:last-child {
            margin-left: 5px;

            strong {
              font-weight: $font-weight-base;
              font-size: $font-size-md;
            }
          }
        }
      }
    }

    &__modal {

      &-text {
        margin-top: 20px;

        p {
          margin-bottom: 15px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      &-cots-extrabeds {
        ::v-deep {

          .cots-extrabeds {

            &__item {
              flex-basis: 100%;

              @include media-breakpoint-up(lg) {
                flex-basis: 50%;
              }
            }
          }
        }
      }

    }
  }

</style>