<template>
  <div class="checkout-view">
    <b-container>

      <section class="section">
        <b-row>
          <b-col md="12" xl="9">
            <checkout-content-block :solution="selected_solution" :hotel="hotel_data" @comments="update_comments" />
          </b-col>
          <b-col md="12" xl="3">
            <sidebar
              :external_checkout_conditions="[
                {
                  sidebar_validation: (selected_solution.checkout_payload ? selected_solution.checkout_payload.accomodations.multiroom_assignment : false),
                  sidebar_message: 'Uno o più gruppi non sono adatti alle camere selezionate. Cambia le camere che hai scelto o cambia l\'assegnazione dei gruppi.',
                  sidebar_lock: true,
                }
              ]"
              :validate_on_mount="validate_on_mount"
              :integration="integration"
              :availability="get_availability()"
              :live_price="selected_solution.checkout_payload.live_price"
              :loading="loading"
              :purchasing="purchasing"
              :completed="completed"
              :discount_codes="discount_codes"
              @purchase="sidebar_data => { book(sidebar_data) }"
              @commit="event => { this.$store.commit(event.name, event.payload) }">

              <!--slot riepilogo ordine-->
              <template #resume>
                <rooms-summary
                  v-if="selected_solution.rooms && Object.keys(selected_solution.rooms).length > 0"
                  :solution="selected_solution"
                />
              </template>

              <!--slot messaggi di stato-->
              <template #message>
                <!--acquisto completato-->
                <div v-if="completed" class="vb-sidebar__block">
                  <div v-if="success" v-html="'Acquisto completato con successo'" />
                  <div v-else v-html="'C\'è stato un problema.'" />
                </div>
                <!--acquisto in corso-->
                <div v-if="!completed && purchasing" class="text-center">
                  <loader>
                    <template>
                      <span>Prenotazione in corso... non abbandonare o ricaricare la pagina!</span>
                    </template>
                  </loader>
                </div>
              </template>

              <!--slot libero prima del pulsante di acquisto-->
              <template #before_purchase>
                <p class="text-left mb-0">
                  Se procedi alla prenotazione dichiari di accettare la <a href="https://www.booking.com/content/privacy.it.html" target="_blank">Privacy policy</a>
                  e le <a href="https://www.booking.com/content/terms.it.html" target="_blank">Condizioni d'uso</a> di <span class="text-primary">Booking.com</span>
                </p>
              </template>
            </sidebar>
          </b-col>
        </b-row>
      </section>

    </b-container>
  </div>
</template>

<script>
import CheckoutContentBlock from '@booking/components/views/content-blocks/checkout.vue'
import RoomsSummary from '@booking/components/blocks/rooms-summary.vue'
import { mapState } from 'vuex'
import { service_handler } from '@src/services'
import { custom_event, get_config } from "@src/utilities"

export default {
  name: 'checkout-view',

  components: {
    'checkout-content-block': CheckoutContentBlock,
    'rooms-summary': RoomsSummary,
  },

  props: {
  },

  data() {
    return {
      integration: this.$config.integration,
      loading: false,                         // caricamento dati
      purchasing: false,                      // sidebar completatea -> acquisto in corso
      completed: false,                       // acquisto completato
      success: false,                         // esito della purchase() -> cambia il messaggio mostrato all'utente
      validate_on_mount: true,
      message: ''
    }
  },

  computed: {
    ...mapState(['discount_codes']),
    ...mapState(
      'livebk', [
        'hotel_data',
        'selected_solution',
      ]
    ),
  },

  created() {
  },

  mounted() {
  },

  methods: {

    book(sidebar_data) {
      let book = {
        bookingData: {
          ...this.selected_solution.checkout_payload,
          payments: sidebar_data.payments,
          booker_email: sidebar_data.guest.payload.guestEmail,
          booker_telephone: sidebar_data.guest.payload.guestPhone,
          comments: this.message,
          //booker_country: "it",
          //travel_purpose: "leisure",
          //user_platform: "desktop",
          //live_price: 666,
        },
        guestFirstName: sidebar_data.guest.payload.guestFirstName,
        guestLastName: sidebar_data.guest.payload.guestLastName,
        guestFiscalCode: sidebar_data.guest.payload.guestFiscalCode,
        guestEmail: sidebar_data.guest.payload.guestEmail,
        guestEmailConf: sidebar_data.guest.payload.guestEmailConf,
        guestPhone: sidebar_data.guest.payload.guestPhone,
        guestPrivacy: sidebar_data.guest.payload.guestPrivacy,
        guestGdpr: sidebar_data.guest.payload.guestGdpr
      }

      if (sidebar_data.guest.payload.beneficiaryId) book.beneficiaryId = sidebar_data.guest.payload.beneficiaryId

      if (sidebar_data.bill_to_pax !== false) {
        book.guestAddress = sidebar_data.bill_to_pax.payload.guestAddress
        book.guestCity = sidebar_data.bill_to_pax.payload.guestCity
        book.guestPostalCode = sidebar_data.bill_to_pax.payload.guestPostalCode
        book.guestProvince = sidebar_data.bill_to_pax.payload.guestProvince
        book.guestFiscalCode = sidebar_data.bill_to_pax.payload.guestFiscalCode        
      }
      
      this.purchasing = true
      this.success = false
      window.scrollTo(0,0)

      service_handler(
        'book',
        book,
      ).then(
        (success) => {
          this.success = true
          this.purchasing = false
          this.completed = true
          window.scrollTo(0,0) // la sidebar sparisce quasi per intero => riporto lo scroll della pagina in alto

          custom_event('confirmedCheckout', get_config('integration.company'), success.data.live_price, 'partnerBehaviour')
          if(success.data.boughtProductTypes){
            success.data.boughtProductTypes.forEach(productType => {
              custom_event('book', productType, success.data.live_price, 'productType')
            })
          }
          
          /*
          // da valutare: a prenotazione completata cancello i dati necessari al checkout ( => per un'altra prenotazione bisogna ripassare per la ricerca)
          this.$store.commit('livebk/hotel_data', {})
          this.$store.commit('livebk/selected_solution', {})
          */

          window.location.href = '/confirm/' + success.data.confirmcode + '/' + success.data.reservation_id.toString()
        },
        (error) => {
          this.log('[book] - error')
          this.log(error.response)
          this.validate_on_mount = true
          this.success = false
          this.purchasing = false
          custom_event('failedCheckout', get_config('integration.company'), book.bookingData.live_price, 'partnerBehaviour')
        }
      )
    },

    update_comments(message) {
      this.message = message;
    }
  },
}
</script>

<style lang="scss" scoped>

  .checkout-view {

    .rooms-summary {
      background:$white;
      padding: 20px;
      margin-bottom: 20px;
      border-radius: $border-radius;
    }
  }

</style>