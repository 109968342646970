var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"checkout-view"},[_c('b-container',[_c('section',{staticClass:"section"},[_c('b-row',[_c('b-col',{attrs:{"md":"12","xl":"9"}},[_c('checkout-content-block',{attrs:{"solution":_vm.selected_solution,"hotel":_vm.hotel_data},on:{"comments":_vm.update_comments}})],1),_c('b-col',{attrs:{"md":"12","xl":"3"}},[_c('sidebar',{attrs:{"external_checkout_conditions":[
              {
                sidebar_validation: (_vm.selected_solution.checkout_payload ? _vm.selected_solution.checkout_payload.accomodations.multiroom_assignment : false),
                sidebar_message: 'Uno o più gruppi non sono adatti alle camere selezionate. Cambia le camere che hai scelto o cambia l\'assegnazione dei gruppi.',
                sidebar_lock: true,
              }
            ],"validate_on_mount":_vm.validate_on_mount,"integration":_vm.integration,"availability":_vm.get_availability(),"live_price":_vm.selected_solution.checkout_payload.live_price,"loading":_vm.loading,"purchasing":_vm.purchasing,"completed":_vm.completed,"discount_codes":_vm.discount_codes},on:{"purchase":sidebar_data => { _vm.book(sidebar_data) },"commit":event => { this.$store.commit(event.name, event.payload) }},scopedSlots:_vm._u([{key:"resume",fn:function(){return [(_vm.selected_solution.rooms && Object.keys(_vm.selected_solution.rooms).length > 0)?_c('rooms-summary',{attrs:{"solution":_vm.selected_solution}}):_vm._e()]},proxy:true},{key:"message",fn:function(){return [(_vm.completed)?_c('div',{staticClass:"vb-sidebar__block"},[(_vm.success)?_c('div',{domProps:{"innerHTML":_vm._s('Acquisto completato con successo')}}):_c('div',{domProps:{"innerHTML":_vm._s('C\'è stato un problema.')}})]):_vm._e(),(!_vm.completed && _vm.purchasing)?_c('div',{staticClass:"text-center"},[_c('loader',[[_c('span',[_vm._v("Prenotazione in corso... non abbandonare o ricaricare la pagina!")])]],2)],1):_vm._e()]},proxy:true},{key:"before_purchase",fn:function(){return [_c('p',{staticClass:"text-left mb-0"},[_vm._v(" Se procedi alla prenotazione dichiari di accettare la "),_c('a',{attrs:{"href":"https://www.booking.com/content/privacy.it.html","target":"_blank"}},[_vm._v("Privacy policy")]),_vm._v(" e le "),_c('a',{attrs:{"href":"https://www.booking.com/content/terms.it.html","target":"_blank"}},[_vm._v("Condizioni d'uso")]),_vm._v(" di "),_c('span',{staticClass:"text-primary"},[_vm._v("Booking.com")])])]},proxy:true}])})],1)],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }