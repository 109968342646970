var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-col',{attrs:{"md":"12"}},[_c('section',{staticClass:"conditions"},[_c('p',{staticClass:"conditions__title",domProps:{"innerHTML":_vm._s('Condizioni per i bambini e letti supplementari')}}),_c('div',{staticClass:"conditions__wrap"},[_c('div',{staticClass:"conditions__item"},[_c('p',{staticClass:"conditions__item-title",domProps:{"innerHTML":_vm._s('<span class=\'semibold\'>Condizioni per i bambini</span>')}}),_c('p',{staticClass:"conditions__item-desc",domProps:{"innerHTML":_vm._s(
          'Il prezzo indicato si riferisce al numero di adulti e bambini.<br/> ' +
          'Assicurati di aver aggiunto alla ricerca il numero esatto di bambini che soggiorneranno e la loro età, perché questi dettagli incidono sul prezzo.')}})]),_c('div',{staticClass:"conditions__item"},[_c('p',{staticClass:"conditions__item-title",domProps:{"innerHTML":_vm._s('<span class=\'semibold\'>Condizioni per culle e letti aggiuntivi</span>')}}),_c('ul',{staticClass:"conditions__item-list"},[_c('li',{staticClass:"conditions__item-li",domProps:{"innerHTML":_vm._s('Le richieste sono soggette a disponibilità')}}),_c('li',{staticClass:"conditions__item-li",domProps:{"innerHTML":_vm._s('Le richieste devono essere confermate, <span class=\'semibold\'>contatta la struttura</span>')}}),_c('li',{staticClass:"conditions__item-li",domProps:{"innerHTML":_vm._s('Le richieste non contrassegnate con \'Gratis\' potrebbero comportare costi extra')}})])]),_c('span',{directives:[{name:"b-modal",rawName:"v-b-modal",value:('modal_conditions'),expression:"'modal_conditions'"}],staticClass:"conditions__read",domProps:{"innerHTML":_vm._s('Leggi tutte le condizioni per culle e letti supplementari')}}),_c('vb-modal',{attrs:{"vb_options":{
          id: 'modal_conditions',
          body_class: 'conditions__modal',
          title: 'Condizioni per culle e letti aggiuntivi',
          size: 'lg',
          centered: true,
          hide_footer: true
        }}},[_c('cots-extrabeds',{staticClass:"conditions__modal-cots-extrabeds",attrs:{"hotel":_vm.hotel}}),_c('div',{staticClass:"conditions__modal-text"},[_c('p',{domProps:{"innerHTML":_vm._s('I supplementi non sono inclusi nell’importo totale e devono essere pagati separatamente durante il soggiorno.')}}),_c('p',{domProps:{"innerHTML":_vm._s('Il numero massimo di letti e culle extra dipende dalla sistemazione che hai scelto. Verifica la tua sistemazione per sapere qual è l\'occupazione massima.')}}),_c('p',{domProps:{"innerHTML":_vm._s('Le culle e i letti supplementari sono soggetti a disponibilità. <strong class=\'semibold\'>Contatta la struttura</strong>.')}})])],1),_c('div',{staticClass:"conditions__extrabeds"},[(_vm.hotel.additional_policies.cots_and_extra_beds.length)?_c('p',{staticClass:"conditions__extrabeds-title",domProps:{"innerHTML":_vm._s('In questa struttura sono soggetti a disponibilità: ')}}):_vm._e(),_c('ul',{staticClass:"conditions__extrabeds-list"},[_vm._l((_vm.hotel.additional_policies.cots_and_extra_beds),function(extra_bed,extra_bed_index){return [_c('li',{key:_vm._uid + extra_bed_index,staticClass:"conditions__extrabeds-li"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.get_cots_extrabeds(extra_bed.rate_type).name)}}),_c('span',[_c('strong',[_vm._v("da "+_vm._s(extra_bed.from_age)+" "),(!extra_bed.to_age)?_c('span',[_vm._v("anni e oltre")]):_vm._e()]),(extra_bed.to_age)?_c('strong',{domProps:{"innerHTML":_vm._s('a ' + extra_bed.to_age + ' anni')}}):_vm._e()])])]})],2)])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }